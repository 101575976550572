import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Input } from '@rebass/forms/styled-components';
import { Box, Button, Flex } from 'rebass/styled-components';
import { Logo } from './Logo';
import { colors } from './theme.js';

export const LoginForm = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const onSubmit = (event) => {
        event.preventDefault();

        props.onSubmit({ username, password });
    };

    return (
        <Box
            height="100%"
            width="100%"
            backgroundColor="#121212"
        >
            <Flex justifyContent="center" alignItems="center" height="100%">
                <Box
                    marginTop="250px"
                    paddingY={5}
                    paddingX={5}
                    width="90%"
                    maxWidth={500}
                    margin="0 auto"
                    backgroundColor="#fff1"
                >
                    <Box onSubmit={onSubmit} as="form">
                        <Logo />

                        <Input
                            placeholder="Enter your email address"
                            value={username}
                            onChange={(event) => setUsername(event.target.value)}
                            width="100%"
                            maxWidth="100%"
                            marginTop={5}
                            padding={3}
                            backgroundColor="#fff1"
                            sx={{border: 'none'}}
                        />

                        <Input
                            placeholder="Enter your password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            width="100%"
                            maxWidth="100%"
                            marginTop={3}
                            type="password"
                            padding={3}
                            backgroundColor="#fff1"
                            sx={{border: 'none'}}
                        />

                        <Button variant="primary" padding={3} marginTop={3} width="100%" backgroundColor={colors.purple.base}>
                          LOGIN
                        </Button>
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
};

LoginForm.propTypes = {
    onSubmit: PropTypes.func
};
