import React from 'react';

export const Logo = () => {
    return (
        <img
            src='/logo-white.svg'
            alt="Closelink World"
            style={{ margin: '0 auto', display: 'block', width: '40px' }}
        />
    );
};
