import axios from 'axios';
import sha1 from 'js-sha1';

const TOKEN_KEY = 'token';

export const getActivities = () => {
    return axios
        .get('/api/v1/global-activities', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`
            }
        })
        .then((response) => response.data);
};

export const login = ({ username, password }) => {
    const sha1Password = sha1(password);
    const basicAuthCredentials = btoa(`${username}:${sha1Password}`);

    return axios
        .get('/api/login', {
            headers: {
                Authorization: `Basic ${basicAuthCredentials}`
            }
        })
        .then((response) => response.data);
};
