import { colors } from './theme';
import { uniqWith, isEqual, isEmpty } from 'lodash';

const getById = (arr, id) => arr.find(i => i.id === id);

const transformVesselData = ({ vessels, customers }) =>
    vessels
        .filter(v => !!v.location)
        .map(v => {
            const customer = getById(customers, v.customerId);
            return {
                id: v.id,
                type: 'vessel',
                name: v.name,
                lat: v.location.lat,
                lng: v.location.lng,
                color: colors.red.medium,
                height: 0.02,
                radius: 0.5,
                data: { ...v, customerName: customer.name },
                heading: v.heading
            };
        });

const transformPortsData = ports =>
    ports.map(p => {
        return {
            id: p.id,
            name: p.name,
            type: 'port',
            text: p.name,
            label: p.name,
            lat: p.location.lat,
            lng: p.location.lng,
            color: colors.red.medium,
            height: 0.01,
            radius: 2,
            data: p
        };
    });

const transformOffersData = ({ offers, vessels, ports, customers, suppliers }) =>
    uniqWith(
        offers
            .map(o => {
                const vessel = getById(vessels, o.vesselId);
                const port = getById(ports, o.portId);
                const customer = getById(customers, o.customerId);
                const supplier = getById(suppliers, o.supplierId);

                if (isEmpty(vessel.location)) {
                    return false;
                }

                return {
                    name: `Offer ${o.offerNumber}: ${vessel.name} <-> ${port.name}`,
                    startLat: vessel.location.lat,
                    startLng: vessel.location.lng,
                    endLat: port.location.lat,
                    endLng: port.location.lng,
                    color: colors.blue.medium,
                    type: 'offer',
                    data: { ...o, customerName: customer.name, supplierName: supplier.name }
                };
            })
            .filter(t => !!t),
        isEqual
    );

const transformOrdersData = ({ orders, vessels, ports, customers }) =>
    uniqWith(
        orders
            .map(o => {
                const vessel = getById(vessels, o.vesselId);
                const port = getById(ports, o.portId);
                const customer = getById(customers, o.customerId);

                if (isEmpty(vessel.location)) {
                    return false;
                }

                return {
                    name: `Order ${o.orderNumber}: ${vessel.name} <-> ${port.name}`,
                    startLat: vessel.location.lat,
                    startLng: vessel.location.lng,
                    endLat: port.location.lat,
                    endLng: port.location.lng,
                    color: colors.red.medium,
                    type: 'order',
                    data: { ...o, customerName: customer.name }
                };
            })
            .filter(t => !!t),
        isEqual
    );

const transformData = ({ vessels, ports, offers, orders, customers, suppliers }) => {
    return {
        vesselsData: transformVesselData({ vessels, customers }),
        portsData: transformPortsData(ports),
        transactionsData: [
            ...transformOffersData({ offers, vessels, ports, customers, suppliers }),
            ...transformOrdersData({ orders, vessels, ports, customers })
        ]
    };
};

export { transformData };
