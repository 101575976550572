import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { default as ReactGlobe } from 'react-globe.gl';
import styled from 'styled-components';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { transformData } from './TransformData';

const GlobeWrapper = styled.div``;

const Globe = props => {
    const [vesselObj, setVesselObj] = useState();
    const [portObj, setPortObj] = useState(); // eslint-disable-line no-unused-vars
    const [zoomLevel, setZoomLevel] = useState(1); // eslint-disable-line no-unused-vars
    const [data, setData] = useState({
        vesselsData: [],
        portsData: [],
        transactionsData: []
    });

    const globeEl = React.useRef();
    const { vessels, offers, orders, ports, customers, suppliers } = props.data;

    React.useEffect(() => {
        setData(transformData({ vessels, orders, offers, ports, customers, suppliers }));
        const loader = new GLTFLoader();

        loader.load(
            'objects/boat.glb',
            function(glb) {
                const model = glb.scenes[0];
                model.scale.setScalar(0.4);
                setVesselObj(model);
            },
            undefined,
            function(error) {
                console.error(error);
            }
        );
        loader.load(
            'objects/port.glb',
            function(glb) {
                const model = glb.scenes[0];
                model.scale.setScalar(0.05);
                setPortObj(model);
            },
            undefined,
            function(error) {
                console.error(error);
            }
        );

    }, [offers, orders, ports, vessels, customers, suppliers]);

    const onClick = type => (point, event) => {
        props.onItemClick && props.onItemClick(point);
    };

    return (
        <GlobeWrapper>
            <ReactGlobe
                ref={globeEl}
                globeImageUrl="/earth-night.jpg"
                backgroundColor="#121212"
                arcsData={data.transactionsData}
                arcColor="color"
                arcStroke={0.4}
                arcDashLength={0.1}
                arcDashGap={0.1}
                arcDashAnimateTime={5000}
                onArcClick={onClick('transaction')}
                customLayerData={data.vesselsData}//[...data.vesselsData,...data.portsData]
                customThreeObject={vesselObj}
                onZoom={({altitude})=> {
                    setZoomLevel(altitude);
                }}
                customThreeObjectUpdate={(obj, d) => {
                    Object.assign(obj.position, globeEl.current.getCoords(d.lat, d.lng, d.alt));
                    obj.lookAt(0, 0, 0);
                    obj.scale.setScalar(0.25);
                    obj.rotateZ(d.heading); //TODO this is simply wrong, because vessel are not heading north initally
                }}
                onCustomLayerClick={onClick('custom')}
                labelsData={data.portsData}
                labelSize={0.75}
                labelAltitude={0.01}
                labelDotRadius={0.75}
                labelColor='color'
                onLabelClick={onClick('label')}
            />
        </GlobeWrapper>
    );
};

Globe.propTypes = {
    data: PropTypes.object,
    onPointClick: PropTypes.func
};

export { Globe };
