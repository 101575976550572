import React from 'react';
import { Flex } from 'rebass/styled-components';

import { GlobalStyle } from './GlobalStyle';
import { useAuth } from './useAuth';

import { AuthenticatedApp } from './AuthenticatedApp';
import { UnauthenticatedApp } from './UnauthenticatedApp';

function App() {
    const { isLoggedIn } = useAuth();

    return (
        <React.Fragment>
            <GlobalStyle />

            <Flex alignItems="center" justifyContent="center" height="100vh" backgroundColor={"#121212"}>
                {isLoggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}
            </Flex>
        </React.Fragment>
    );
}

export default App;
