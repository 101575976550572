import React from 'react';

import { Box, Flex, Text, Button } from 'rebass/styled-components';
import styled from 'styled-components';
import { CloseIcon } from './icons.js';
import { colors } from './theme.js';

const Stack = styled.div`
    > * {
        margin-bottom: 24px;
        padding-left: 24px;
        padding-right: 24px;
    }
    > *:not(:last-of-type) {
        border-bottom: 1px solid #fff1;
        padding-bottom: 24px;
    }
`;

const Link = styled.a.attrs((props) => ({
    href: props.to,
    target: '_blank',
    rel: 'noopener noreferrer'
}))`
    color: ${colors.blue.light};
    text-decoration: undeline;
    transition: 0.3s;

    &:hover {
        color: white;
        transition: 0.3s;
    }
`;

const LocationLink = ({ location: { lat, lng } }) => (
    <Link to={`http://maps.google.com/?q=${lat},${lng}`}>
        {lat}, {lng}
    </Link>
);

const Detail = ({ headline, information }) => {
    return (
        <Box>
            <Text fontWeight="bold">{headline}</Text>
            <Text color="#fffa" marginTop={1}>
                {information}
            </Text>
        </Box>
    );
};

const VesselDetails = ({ data }) => {
    const vesselLink = `https://www.closelink.com/app/vessel/${data.id}`;
    const customerLink = `https://www.closelink.com/app/customer/detail/${data.customerId}`;

    return (
        <React.Fragment>
            <Detail headline="Name" information={<Link to={vesselLink}>{data.name}</Link>} />
            <Detail headline="Location" information={<LocationLink location={data.location} />} />
            <Detail headline="IMO" information={<Link to={vesselLink}>{data.imo}</Link>} />
            <Detail
                headline="Customer"
                information={<Link to={customerLink}>{data.customerName}</Link>}
            />
            <Detail headline="Navigation Status" information={data.navigationStatus} />
            <Detail headline="Speed" information={`${data.speed} kt`} />
        </React.Fragment>
    );
};

const PortDetails = ({ data }) => {
    const portUrl = `https://www.closelink.com/app/port/detail/${data.id}`;

    return (
        <React.Fragment>
            <Detail headline="Name" information={<Link to={portUrl}>{data.name}</Link>} />
            <Detail headline="Location" information={<LocationLink location={data.location} />} />
            <Detail headline="UN/LOCODE" information={data.locode} />
            <Detail headline="Country" information={data.country.name} />
        </React.Fragment>
    );
};

const OfferDetails = ({ data }) => {
    return (
        <React.Fragment>
            <Detail headline="Offer Number" information={data.offerNumber} />
            <Detail headline="Customer" information={data.customerName} />
            <Detail headline="Supplier" information={data.supplierName} />
            <Detail headline="Date Delivery" information={data.dateDelivery} />
            <Detail headline="Volume" information={data.volume} />
            <Detail headline="Total" information={`${data.total.value} ${data.total.currency}`} />
            <Detail headline="Link" information={<Link to={data.offerLink}>Go to offer</Link>} />
        </React.Fragment>
    );
};

const OrderDetails = ({ data }) => {
    return (
        <React.Fragment>
            <Detail headline="Order Number" information={data.orderNumber} />
            <Detail headline="Customer" information={data.customerName} />
            <Detail headline="Date Delivery" information={data.dateDelivery} />
            <Detail headline="Offer Count" information={data.offerCount} />
            <Detail
                headline="Link"
                information={<Link to={data.orderLink}>Go to order</Link>}
                marginTop={2}
            />
        </React.Fragment>
    );
};

const SidebarDetails = ({ type, data }) => {
    switch (type) {
        case 'vessel': {
            return <VesselDetails data={data} />;
        }
        case 'order': {
            return <OrderDetails data={data} />;
        }
        case 'offer': {
            return <OfferDetails data={data} />;
        }
        case 'port': {
            return <PortDetails data={data} />;
        }
        default: {
            return null;
        }
    }
};

const Sidebar = ({ type, data, onCloseClick }) => {
    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                bottom: 0
            }}
            width={['100%', '50vw', '42vw', '33vw']}
            backgroundColor="#FFF1"
            padding={5}
            overflow="auto"
        >
            <Flex width="100%" justifyContent="flex-end" marginBottom={4}>
                <Button
                    display="inline-flex"
                    alignItems="center"
                    sx={{ backgroundColor: 'transparent' }}
                    onClick={onCloseClick}
                    marginBottom={4}
                >
                    <CloseIcon /> Close
                </Button>
            </Flex>

            {data.photoUrl ? (
                <Box
                    marginBottom={5}
                    sx={{
                        borderRadius: '8px',
                        border: '4px solid #fff4',
                        overflow: 'hidden'
                    }}
                >
                    <img
                        style={{ width: '100%', height: 'auto', display: 'block' }}
                        src={data.photoUrl}
                        alt={data.name}
                    />
                </Box>
            ) : null}

            <Stack>
                <SidebarDetails data={data} type={type} />
            </Stack>
        </Box>
    );
};

export { Sidebar };
