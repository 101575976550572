import React from 'react';
import { LoginForm } from './LoginForm';
import { useAuth } from './useAuth';

export const UnauthenticatedApp = () => {
    const { login } = useAuth();

    return (
        <React.Fragment>
            <LoginForm onSubmit={login} />
        </React.Fragment>
    );
};
