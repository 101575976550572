import React from 'react';
import { useQuery } from 'react-query';
import { Box, Button } from 'rebass/styled-components';
import RingLoader from "react-spinners/RingLoader";

import { colors } from './theme';
import { getActivities } from './queries';
import { Globe } from './Globe';
import { Sidebar } from './Sidebar';
import { useAuth } from './useAuth';

const LogoutButton = ({ onClick }) => {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                zIndex: 2
            }}
        >
            <Button backgroundColor={colors.purple.base} variant="primary" onClick={onClick}>
              LOGOUT
            </Button>
        </Box>
    );
};

export const AuthenticatedApp = () => {
    const { logout } = useAuth();
    const { isSuccess, data } = useQuery('activities', getActivities, {
        refetchInterval: 60000
    });
    const [showSidebar, setShowSidebar] = React.useState(false);
    const [activePoint, setActivePoint] = React.useState(false);

    const onItemClick = (point) => {
        console.log('onitemclick', point)
        setActivePoint(point);
        setShowSidebar(true);
    };

    if (!isSuccess) {
        return (
            <React.Fragment>
            <LogoutButton onClick={() => logout()} />
            <RingLoader
                size={100}
                color={colors.purple.base}
                loading={true}
            />
            </React.Fragment>
        );
    }

    const onSidebarCloseClick = () => {
        setShowSidebar(false)
    }

    return (
        <React.Fragment>
            <LogoutButton onClick={() => logout()} />
            <Globe data={data} onItemClick={onItemClick} />
            {showSidebar ? <Sidebar onCloseClick={onSidebarCloseClick} type={activePoint.type} data={activePoint.data} /> : null}
        </React.Fragment>
    );
};
