import React, { useState } from 'react';
import { login as loginApi } from './queries';

export const AuthContext = React.createContext({});

const TOKEN_KEY = 'token';

const setLocalStorageToken = (token) => localStorage.setItem(TOKEN_KEY, token);
const getLocalStorageToken = () => localStorage.getItem(TOKEN_KEY);

export const AuthContextProvider = ({ children }) => {
    const [localToken, setLocalToken] = useState(() => getLocalStorageToken() || '');

    const setToken = (token) => {
        setLocalToken(token);
        setLocalStorageToken(token);
    };

    const logout = () => setToken('');

    const login = ({ username, password }) => {
        loginApi({ username, password }).then((response) => {
            setToken(response.accessToken);
        });
    };

    const value = {
        setToken,
        token: localToken,
        isLoggedIn: !!localToken,
        logout,
        login
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
